<template>
  <div class="page-not-found main-container">
    <div class="container--slim">
      <h1>{{ $t("error_page_not_found_headline") }}</h1>
      <div>{{ $t("error_page_not_found_text") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',

  props: {
    error: {
      type: String,
      default: '',
    },
  },

};

</script>

<style lang="scss" scoped>

.page-not-found {

  &__icon {
    margin: auto;
    width: 100px;
    height: 100px;
    color: $error-red;
  }
}

</style>
